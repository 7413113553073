
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";

import ApiService from "@/core/services/ApiService";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { string } from "yup/lib/locale";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";

interface NewData {
  file: any;
  fileName: string;
}

interface items {
  listSekolah: any;
  disableButton: boolean;
  fileInputKey: any;
  errorfile: string;

  total: string;
  valid: string;
  unValid: string;
  unValidData: any;

  role: string;
}

export default defineComponent({
  name: "new-target-modal",
  props: {
    schoolId: String,
    // getData: Function,
  },
  emits: ["getData"],
  components: {
    Loading,
    Multiselect,
    Field,
  },
  data() {
    return {
      showDropdownBtnHDownload: false,
    };
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const uploadModalcardStatusRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const file = String;

    const toast = useToast();
    const router = useRouter();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    let items = reactive<items>({
      listSekolah: [],
      disableButton: false,
      fileInputKey: 0,
      errorfile: "",

      total: "",
      valid: "",
      unValid: "",
      unValidData: [],

      role: "",
    });

    const targetData = reactive<NewData>({
      file: "",
      fileName: "",
    });

    function handleFileUpload(event) {
      targetData.file = event.target.files[0];
      targetData.fileName = event.target.files[0].name;
    }

    function downloadTemplateCard() {
      const toast = useToast();
      // toast.error("File Belum Ada");
      loading.value = true;
      ApiService.getDownload("crmv2/main_card/util/template/verify/download")
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute(
            "download",
            "Template_upload_data_kartu_tercetak" + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          loading.value = false;

          toast.success("Sukses Unduh File");
          loading.value = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          loading.value = false;
        });
    }

    const templateExcel = () => {
      window.open(
        "https://drive.google.com/uc?export=download&id=1U5h8iPhoWHr2jw9piZcyLU45HD5JCfAs",
        "_blank"
      );
    };

    const batalUpload = () => {
      targetData.file = null;
      targetData.file = "";
      targetData.fileName = "";
      items.fileInputKey++;

      items.errorfile = "";
    };

    const kembaliSekolah = () => {
      location.reload();
    };

    onMounted(() => {
      items.role = JSON.parse(localStorage.getItem("user_account")!).role;
    });

    return {
      targetData,
      //   submit,
      loading,
      formRef,
      uploadModalcardStatusRef,
      handleFileUpload,
      file,
      items,
      downloadTemplateCard,
      templateExcel,
      batalUpload,
      //   callModal,
      kembaliSekolah,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
  // beforeMount() {
  //   this.getData;
  // },
  methods: {
    downloadDropdown() {
      if (this.showDropdownBtnHDownload == true) {
        this.showDropdownBtnHDownload = false;
      } else {
        this.showDropdownBtnHDownload = true;
      }
    },
    callModal() {
      this.showDropdownBtnHDownload = false;
      const toast = useToast();
      console.log("shcoolid", this.schoolId);

      if (!this.schoolId) {
        toast.error("Pilih Sekolah terlebih dahulu");
      } else {
        const modal = new Modal(
          document.getElementById("kt_modal_upload_card_status")
        );
        modal.show();
      }
    },
    downloadAllStudent() {
      const toast = useToast();
      // toast.error("File Belum Ada");
      this.loading = true;
      ApiService.getDownload(
        "crmv2/main_card/card_data/office/" + this.schoolId + "/verify/download"
      )
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute(
            "download",
            "Template_upload_data_kartu_semua_siswa" + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.loading = false;

          toast.success("Sukses Unduh File");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.loading = false;
        });
    },
    downloadIsPrint(isPrint) {
      const toast = useToast();
      // toast.error("File Belum Ada");
      this.loading = true;
      ApiService.getDownload(
        "crmv2/main_card/card_data/office/" +
          this.schoolId +
          "/verify/download?isPrint=" +
          isPrint
      )
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute(
            "download",
            "Template_upload_data_kartu_tercetak" + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.loading = false;

          toast.success("Sukses Unduh File");
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.loading = false;
        });
    },
    submit() {
      const toast = useToast();
      if (this.targetData.file === "") {
        this.items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        this.items.errorfile = "";

        this.isLoading = true;

        this.items.disableButton = true;
        this.loading = true;

        // setTimeout(() => {
        let formData = new FormData();

        formData.append("file", this.targetData.file);

        ApiService.postWithData(
          "crmv2/main_card/card_design/office/" +
            this.schoolId +
            "/verify/upload",
          formData
        )
          .then((res) => {
            this.targetData.file = null;
            this.targetData.file = "";

            this.targetData.fileName = "";
            this.items.fileInputKey++;

            this.items.disableButton = false;

            this.items.total = res.data.total;
            this.items.valid = res.data.valid;
            this.items.unValid = res.data.unValid;
            this.items.unValidData = res.data.unValidData;
            hideModal(this.uploadModalcardStatusRef);
            setTimeout(() => {
              this.$emit("getData");
              this.loading = false;
              this.isLoading = false;
              toast.success(res.data.detail);
            }, 2000);

            // location.reload();
          })
          .catch((e) => {
            if (e.response.status == 401) {
              this.targetData.file = null;
              this.targetData.file = "";
              this.targetData.fileName = "";
              this.items.fileInputKey++;

              toast.error(e.response.data.detail);
              this.$router.push("/sign-in");
              this.isLoading = false;
            } else {
              this.targetData.file = null;
              this.targetData.file = "";
              this.targetData.fileName = "";
              this.items.fileInputKey++;

              this.loading = false;
              this.items.disableButton = false;
              toast.error(e.response.data.detail);
              this.isLoading = false;
            }
          });
      }
    },
  },
});
